
<template>
  <!-- <v-container>
    <v-row no-gutters>
      <v-col v-for="app in apps" :key="app.appId" cols="12" sm="12">
        <v-row no-gutters>
          <v-col v-for="ap in app" :key="ap" cols="12" sm="1">
            <v-card class="pa-2" outlined tile>{{ap}}</v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <template slot="no-data">
      <v-alert :value="true" color="error" icon="warning">Error</v-alert>
    </template>
  </v-container>-->
  <v-container>
    <div>
      <v-row>
        <v-col class="text-right" cols="12" sm="12">
          <div class="my-2">
            <!-- <v-btn color="primary" @click="add()">등록</v-btn> -->
            <v-btn color="primary" @click="$router.push('/Admin/Edit')">글쓰기</v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- <div>
      <v-row>
        <v-col class="text" cols="6" sm="6">
          <div class="my-2">
            <div id="container" style="height: 200px;">{{ src }}</div>
          </div>
        </v-col>
      </v-row>
    </div> -->

    <div>
      <v-data-table :headers="headers" :items="apps" :page.sync="page" :items-per-page="itemsPerPage"
        hide-default-footer class="elevation-1" @page-count="pageCount = $event">
        <!-- <template v-slot:item.createdAt="{ item }">{{ item.createdAt | DateTime}}</template> -->
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
        <!-- <v-text-field
        :value="itemsPerPage"
        label="Items per page"
        type="number"
        min="-1"
        max="15"
        @input="itemsPerPage = parseInt($event, 10)"
        ></v-text-field>-->
      </div>
    </div>
  </v-container>
</template>
<script>
// import _ from "lodash";
// import monaco from "monaco-editor";
const monaco = require("monaco-editor");

export default {
  name: "board-list",
  data: function () {
    return {
      src: '',

      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      apps: [],
      headers: [
        {
          text: "No.",
          value: "appId",
          align: "center",
          sortable: false,
          width: "5%"
        },
        {
          text: "제목",
          value: "title",
          align: "left",
          width: "15%",
          sortable: false
        },
        {
          text: "설명",
          value: "contents",
          align: "left",
          width: "*",
          sortable: false
        },
        {
          text: "생성일",
          value: "createdAt",
          align: "left",
          width: "12%",
          sortable: false
        }
        // {
        //   text: "Actions",
        //   value: "action",
        //   sortable: false,
        //   align: "center",
        //   width: "6%"
        // }
      ]
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    }
  },
  mounted() {
    this.initialize();

    // window.editor = 

    //       monaco.editor.create(document.getElementById('container'), {
    //       value: `function x() {
    //   console.log("Hello world!");
    // }`,
    //       language: 'java',
    //       theme: 'vs-dark',
    //     });
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    search() {
      this.initialize();
    }
  },
  created() { },
  methods: {
    clickApp() {
      //   var self = this;
      //   this.selectedApp = app;
      // this.getTab().then(self.getArticle);
    },

    getApps() {
      var self = this;
      return new Promise(function (resolve) {
        // if (!!tabId) self.selectedTab.tabId = tabId;
        // self.$http.get("/api/app/0/20" + "?isWeb=false").then(response => {
        self.$http.get("https://api.github.com/users/djqkdlem").then(response => {
          console.log(response)
          // self.apps = response.data.data.list;
          // self.apps = [{
          //   appId: 1,
          //   title: '',
          //   contents: '',
          //   createdAt: ''
          // },
          // {
          //   appId: 2,
          //   title: '',
          //   contents: '',
          //   createdAt: ''
          // },
          // {
          //   appId: 3,
          //   title: '',
          //   contents: '',
          //   createdAt: ''
          // }
          // ]
          resolve(resolve);
        });
      });
    },
    initialize: function () {
      let self = this;
      self.apps = [{
        appId: 1,
        title: 'test1',
        contents: 'zzzzzz',
        createdAt: '2022-07-13'
      },
      {
        appId: 2,
        title: 'test2',
        contents: 'zzz2',
        createdAt: '2022-07-13'
      },
      {
        appId: 3,
        title: 'zdf',
        contents: 'zzzs',
        createdAt: '2022-07-13'
      }
      ]


      // self.getApps().catch(e => {
      //   console.log(e);
      // });
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.article.slice();
    },
    clickArticle(item) {
      this.selectedArticle = item;
    }
  }
};
</script>

<!-- 

let names = ['iliakan', 'Violet-Bora-Lee', 'jeresig'];

let requests = names.map(name => fetch(`https://api.github.com/users/${name}`));

Promise.all(requests)
  .then(responses => {
    // 모든 응답이 성공적으로 이행되었습니다.
    for(let response of responses) {
      alert(`${response.url}: ${response.status}`); // 모든 url의 응답코드가 200입니다.
    }

    return responses;
  })
  // 응답 메시지가 담긴 배열을 response.json()로 매핑해, 내용을 읽습니다.
  .then(responses => Promise.all(responses.map(r => r.json())))
  // JSON 형태의 응답 메시지는 파싱 되어 배열 'users'에 저장됩니다.
  .then(users => users.forEach(user => alert(user.name))); -->