<template>
  <div>View</div>
</template>

<script>
export default {
  name: "board-view",

  data: () => ({
    loading: false,
    selection: 1
  }),
  created() {
    // var self = this;
    // return new Promise(function(resolve) {
    //   // if (!!tabId) self.selectedTab.tabId = tabId;
    //   self.$http
    //     .get("/api/article/1/10" + "?tabId=" + 1 + "&isWeb=true")
    //     .then(response => {
    //       console.log(response);
    //       resolve(resolve);
    //     });
    // });
  },
  methods: {
  
  }
};
</script>
