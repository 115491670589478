<template>
  <v-container fluid>
    <v-layout align-center justify-center>
      <v-row no-gutters>
        <v-col v-for="n in 4" :key="n" cols="12" sm="3">
          <Main />
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import Main from "@/components/Main";


export default {
  name: "web-part",
  components: {
    Main
  },
  data() {
    return {
      dados: [{
        name: 'Teste',
        age: 13
      }],
      options: {
        columns: [{
          title: 'Name',
          field: 'name',
          sorter: 'string',
          width: 200,
          editor: true,
        },],
      }
    }
  },
  created() { },
  methods: {
    reserve() { }
  }
};
</script>
