<template>
  <v-row>
    <v-col cols="12" sm="12">
      <!-- <v-date-picker v-model="dates" :full-width="true" range color="#E040FB" :readonly="true"></v-date-picker> -->
    </v-col>

  </v-row>
</template>

<!-- // import _ from "lodash"; -->
<script>
export default {
  name: "board-list",
  data: () => ({
    dates: ["2022-05-18", "2022-05-20"],
    menu: true
  }),
  computed: {},
  methods: {
    functionEvents(date) {
      console.log(date)
      // const [, , day] = date.split("-");
      
      // if ([1, 19, 22].includes(parseInt(day, 10))) return ["red", "#00f"];
      return false;
    }
  }
};
</script>
