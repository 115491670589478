<style>
.ck-editor__editable {
  min-height: 300px;
}
</style>

<template>
  <div id="NewBoard">
    <v-row>
      <v-col cols="12">
        <!-- <v-btn color="primary" @click="add()">등록</v-btn> -->
        <v-breadcrumbs  :items="items" divider=">"></v-breadcrumbs>
      </v-col>
    </v-row>

    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
    <div>
      <v-row>
        <v-col class="text-right" cols="12" sm="12">
          <div class="my-2">
            <!-- <v-btn color="primary" @click="add()">등록</v-btn> -->
            <v-btn color="primary" @click="$router.push('/Admin/List')">등록</v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <code>{{ editorData }}</code>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
  name: "NewBoard",
  data() {
    return {
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        // The configuration of the editor.
      },
      items: [
        {
          text: '홈',
          disabled: false,
          href: 'breadcrumbs_dashboard',
        },
        {
          text: '링크',
          disabled: false,
          href: 'breadcrumbs_link_1',
        }
      ]
    };
  },
  created() {
    // var self = this;
    // return new Promise(function(resolve) {
    //   // if (!!tabId) self.selectedTab.tabId = tabId;
    //   self.$http
    //     .get("/api/article/1/10" + "?tabId=" + 1 + "&isWeb=true")
    //     .then(response => {
    //       console.log(response);
    //       resolve(resolve);
    //     });
    // });
  },
  methods: {
    add() {
      alert(1123)
    }
  }
};
</script>
