<template>
    <div id="app">
        <v-app id="inspire">
            <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark>
                <v-container>
                    <v-toolbar-title>
                        <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
                        <v-text-field flat solo-inverted hide-details prepend-inner-icon="search" label="Search"
                            class="w-100" v-model="keyword" @keyup.enter="searchClick"></v-text-field>
                    </v-toolbar-title>
                </v-container>
                <!-- <template>
                    <v-container text-right>
                        <v-icon class="ma-1">home</v-icon>
                        <v-icon class="ma-1">event</v-icon>
                        <v-icon class="ma-1">info</v-icon>
                    </v-container>
                </template> -->
                <v-spacer></v-spacer>
                {{ item }}
                <v-btn icon @click="searchClick()">
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>
            </v-app-bar>
            <v-main>
                <v-container fluid>
                    <template>
                        <v-parallax dark src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg" height="120">
                            <v-row align="center" justify="center">
                                <v-col class="text-center" cols="12">
                                    <h1 class="text-h6 font-weight-thin">
                                        오늘 점심은 뭐 먹을까..?
                                    </h1>
                                    <h5 class="subheading font-weight-thin">
                                        종각역, 판교역, 넥슨 등등 검색해 보세요.
                                    </h5>
                                </v-col>
                            </v-row>
                        </v-parallax>
                    </template>
                    <v-layout>
                        <v-row no-gutters>
                            <v-col v-for="idx in 3" cols="12" sm="4" xs="4">
                                <!-- <v-card :loading="loading" class="mx-auto my-1" cursor @click="openLink(item);">
                                    <v-img height="240" :src=item.thumUrl></v-img>
                                    <v-card-title class="my-1 subtitle-2">
                                        {{ item.name }}
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row class="mx-0">
                                        </v-row>

                                        <template v-for="ca in item.category">
                                            <v-chip color="green" text-color="white" class="ma-2" x-small>
                                                {{ ca }}
                                            </v-chip>
                                        </template>
                                    </v-card-text>
                                </v-card> -->

                                <v-list three-line>
                                    <template v-for="(item, index) in list[idx - 1]">
                                        <v-divider></v-divider>
                                        <v-list-item cursor @click="openLink(item);">
                                            <v-row no-gutters justify="center">
                                                <v-col class="text-left" cols="3">
                                                    <v-img max-width="80" max-height="80" min-height="80"
                                                        :src="item.thumUrl"></v-img>
                                                </v-col>
                                                <v-col class="text-left" cols="9">
                                                    <v-list-item-content class="ml-2">
                                                        <v-list-item-title v-html="item.name"></v-list-item-title>
                                                        <v-list-item-subtitle
                                                            v-html="item.menuInfo"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-col>
                                                <!-- <v-col cols="3">
                                                    <v-btn color="primary" dark>
                                                        메뉴
                                                    </v-btn>
                                                </v-col> -->
                                            </v-row>

                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-layout>
                </v-container>
                <!-- <v-btn bottom color="blue darken-3" dark fab fixed right @click="playLotto()">
                    <i class="gg-dice-5"></i>
                </v-btn> -->
                <v-dialog v-model="dialog" width="800px">
                    <v-card>
                        <v-container grid-list-sm>
                            <v-layout row wrap>
                                <v-card :loading="loading" class="mx-auto my-1" cursor max-width="320" max-height="310"
                                    min-height="310" @click="openLink(selectedItem);">
                                    <!-- <v-img height="240" :src=selectedItem.thumUrl></v-img> -->
                                    <v-card-title class="my-1 subtitle-2">
                                        {{ selectedItem.name }}
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row class="mx-0">
                                        </v-row>

                                        <template v-for="ca in selectedItem.category">
                                            <v-chip color="green" text-color="white" class="ma-2" x-small>
                                                {{ ca }}
                                            </v-chip>
                                        </template>
                                    </v-card-text>
                                </v-card>
                            </v-layout>
                        </v-container>
                        <!-- <v-card-actions>
                            <v-btn text color="primary">More</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="dialog = false">Cancel</v-btn>
                            <v-btn text @click="dialog = false">Save</v-btn>
                        </v-card-actions> -->
                    </v-card>
                </v-dialog>
            </v-main>
        </v-app>
    </div>
</template>

<style>
.gg-dice-5 {
    display: block;
    transform: scale(var(--ggs, 1));
    position: relative;
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    border: 2px solid;
    border-radius: 3px;
}

.gg-dice-5::before {
    content: "";
    display: block;
    box-sizing: border-box;
    background: currentColor;
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 4px;
    left: 2px;
    top: 2px;
    box-shadow:
        0 10px 0,
        10px 0 0,
        10px 10px 0,
        5px 5px 0;
}

.gg-dice-6 {
    display: block;
    transform: scale(var(--ggs, 1));
    position: relative;
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    border: 2px solid;
    border-radius: 3px;
}

.gg-dice-6::before {
    content: "";
    display: block;
    box-sizing: border-box;
    background: currentColor;
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 4px;
    left: 2px;
    top: 2px;
    box-shadow:
        0 5px 0, 0 10px 0,
        10px 0 0, 10px 5px 0,
        10px 10px 0;
}
</style>
<script>


import { list } from "./const.js";

export default {
    name: "App",
    components: {
        // List
        // Edit
    },
    data: () => ({
        dialog: false,
        drawer: false,
        disabled: false,
        keyword: '종각역',
        place: [],
        selectedItem: [],
        list: list,
        loading: false,
        item: _.flatten(list).length
    }),

    created() {

    },
    mounted() {
        // 메뉴 엔터
        // this.list.forEach(x => x.menuInfo ? x.menuInfo = x.menuInfo.replaceAll('|', '<br>') : '')
        let data = _.unionBy(_.flatten(this.list), 'id');
        let size = data.length / 3;
        window.list = _.chunk(data, size)
        this.list = window.list;
        this.item = data.length;
    },
    methods: {
        openLink(item) {
            let url = `https://map.naver.com/v5/entry/place/${item.id}?placePath=/home`
            window.open(url);
        },
        playLotto() {
            this.selectedItem = this.list.sort(x => Math.random() - 0.5).splice(0, 1)[0];
            this.openLink(this.selectedItem);
            // this.dialog = !this.dialog;
        },
        reserve() {
            this.loading = true;
            setTimeout(() => (this.loading = false), 2000);
        },
        searchClick() {
            console.log(this.keyword);
            let self = this;
            this.$http
                .get(`https://0crw4df2cc.execute-api.ap-northeast-2.amazonaws.com/prod?keyword=${this.keyword}`)
                // .get(`https://0crw4df2cc.execute-api.ap-northeast-2.amazonaws.com/local?keyword=${this.keyword}`)
                .then((res) => {
                    let data = _.unionBy(_.flatten(res.data.list), 'id');
                    let size = data.length / 3;
                    window.list = _.chunk(data, size)
                    self.list = window.list;
                    self.item = data.length;
                })
        }
    },
};
</script>