<template>
  <v-card :loading="loading" class="mx-auto my-4" max-width="374">
    <v-img height="240" src="https://cdn.vuetifyjs.com/images/cards/cooking.png"></v-img>

    <v-card-title> <v-badge
          color="pink"
          left
          dot
        >
          Item One
        </v-badge> Cafe Badilico</v-card-title>

    <v-card-text>
      <v-row align="center" class="mx-0">
        <v-rating :value="4.5" color="amber" dense half-increments readonly size="14"></v-rating>

        <div class="grey--text ml-4">4.5 (413)</div>
        <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
      </v-row>

      <div class="my-4 subtitle-1">$ • Italian, Cafe</div>

      <div>Small plates, salads & sandwiches - an intimate setting with 12 indoor seats plus patio seating.</div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-title>Tonight's availability</v-card-title>

    <v-card-text>
      <v-chip-group v-model="selection" active-class="deep-purple accent-4 white--text" column>
        <v-chip>5:30PM</v-chip>

        <v-chip>7:30PM</v-chip>

        <v-chip>8:00PM</v-chip>

        <v-chip>9:00PM</v-chip>
      </v-chip-group>
    </v-card-text>

    <v-card-actions>
      <v-btn color="deep-purple lighten-2" text @click="reserve">Reserve</v-btn>
    </v-card-actions>
  </v-card>
  
</template>

<script>
export default {
  name: "main-view",

  data: () => ({
    loading: false,
    selection: 1
  }),
  created() {
    // var self = this;
    // return new Promise(function(resolve) {
    //   // if (!!tabId) self.selectedTab.tabId = tabId;
    //   self.$http
    //     .get("/api/article/1/10" + "?tabId=" + 1 + "&isWeb=true")
    //     .then(response => {
    //       console.log(response);
    //       resolve(resolve);
    //     });
    // });
  },
  methods: {
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    }
  }
};
</script>
