import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import axios from 'axios'
import router from './router'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import _ from "lodash";

Vue.config.productionTip = false
// axios.defaults.withCredentials = true;
Vue.prototype.$http = axios;

Vue.component('labeledinput', {
  props: ["id"],
  template: `
  <div>
  <label :for="id">Username: {{data}}</label>
  <input type="text" :id="id" v-model="data">
</div>
  `,
  data() {
    return {
      data: ''
    }
  }
})

Vue.use( CKEditor );

new Vue({
  vuetify,
  router,
  _,
  render: h => h(App),
}).$mount('#app')


