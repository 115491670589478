import Vue from 'vue'
import Router from 'vue-router'
// import Main from '@/components/Main'
import Webpart from '@/components/User/Webpart'
import List from '@/components/User/Board/List'
import Admin from '@/components/Admin/Board/List'
import AdminEdit from '@/components/Admin/Board/Edit'
import NewMenu from '@/components/Admin/Menu/View'
import NewMain from '@/components/Admin/Main/View'
import ViewBoard from '@/components/Admin/Board/View'

Vue.use(Router)

export default new Router({
  // mode: 'history',
  routes: [
    {
      path: '/List',
      name: 'List',
      component: List
    },
    {
      path: '/Main',
      name: 'Webpart',
      component: Webpart
    }, {
      path: '/Admin/List',
      name: 'Admin',
      component: Admin
    },
    {
      path: '/Admin/Edit',
      name: 'AdminEdit',
      component: AdminEdit
    },
    {
      path: '/ViewBoard',
      name: 'ViewBoard',
      component: ViewBoard
    },
    {
      path: '/NewMenu',
      name: 'NewMenu',
      component: NewMenu
    },
    {
      path: '/NewMain',
      name: 'NewMain',
      component: NewMain
    }
    ,
    {
      path: '*',
      redirect: ''
    }
  ]
})

// children: [{
//     path: 'new',
//     component: PostNew
//   },
//   {
//     path: ':id',
//     name: 'post',
//     component: PostDetail
//   } //PostDetail
// ]

